import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import HeroCaseBlock from "@components/modules/HeroCaseBlock"
import VideoCaseOverlay from "@components/modules/VideoCaseOverlay"
import NextProjectBlock from "@modules/NextProjectBlock"
import PrevProjectBlock from "@modules/PrevProjectBlock"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollSmoother from "gsap/ScrollSmoother"
import { SEO } from "@root/Seo"

const CaseMain = tw.div`pt-80`
const CaseWrapper = tw.div`mx-auto`

export default function CasesTemplate({ data }) {
  const { main, next, prev } = data
  gsap.registerPlugin(ScrollSmoother, ScrollTrigger)

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
    let smoother
    if (ScrollTrigger.isTouch !== 1) {
      smoother = ScrollSmoother.create()
    }
    return () => {
      if (ScrollTrigger.isTouch !== 1) smoother.kill()
    }
  })

  return (
    <>
      <div id="smooth-wrapper" className="bg-black">
        <div id="smooth-content">
          <React.Suspense fallback={null}>
            <VideoCaseOverlay data={main.postTypeCases.videocaseblock} />
            <CaseMain>
              {prev?.title && <PrevProjectBlock data={prev} />}
              <HeroCaseBlock data={main.postTypeCases.herocaseblock} />
              <CaseWrapper>
                {main.blocks.map((module, index) => {
                  if (module.__typename.includes("WpCore")) return ""
                  const LoadableModuleBlock = React.lazy(() => import(`@modules/${module.__typename}`))
                  return <LoadableModuleBlock data={module} key={`${module.__typename}-${index}`} template="single-case" />
                })}
              </CaseWrapper>
              {next?.title && <NextProjectBlock data={next} />}
            </CaseMain>
          </React.Suspense>
        </div>
      </div>
    </>
  )
}

export const Head = ({ data }) => <SEO seoData={data.main.seo} />

export const query = graphql`
  query ($page: String, $next: String, $prev: String) {
    main: wpCases(id: { eq: $page }) {
      title
      id
      uri
      slug
      content
      contentTypeName
      locale {
        locale
      }
      translated {
        slug
        uri
      }
      translations {
        href
      }
      ...AllCaseCategory
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      ...AllCaseStaticBlocks
      blocks {
        __typename
        ...AllBlocks
      }
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
    }
    next: wpCases(id: { eq: $next }) {
      title
      id
      uri
      ...AllCaseStaticBlocks
    }
    prev: wpCases(id: { eq: $prev }) {
      title
      id
      uri
      ...AllCaseStaticBlocks
    }
  }
`
